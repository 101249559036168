.wrapper {
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.wrapper button{
    padding: 10px 20px;
    background: black;
    color: white;
    font-weight: bold;
    font-size: 20px;
}

.accordian {
  width: 500px;
}

.item {
  background: burlywood;
  margin-bottom: 10px;
  padding: 10px 20px;
}

.title {
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.content {
  color: white;
  height: auto;
}
